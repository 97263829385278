@tailwind base;
@tailwind components;
@tailwind utilities;

*,
:after,
:before {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  letter-spacing: -0.015em;
}

em {
  font-style: normal;
  font-weight: 600;
}

.dots {
  display: flex;
  justify-content: center;
  width: 5rem;

  > div {
    display: flex;
    gap: 0.5rem;

    .dot {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      animation: dot-flashing 0.6s infinite alternate;
    }

    .dot:nth-child(1) {
      animation-delay: 0s;
    }

    .dot:nth-child(2) {
      animation-delay: 0.2s;
    }

    .dot:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes dot-flashing {
  0% {
    background-color: rgba(255, 255, 255, 0.8);
  }
  50%,
  100% {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.bg-coach {
  background: linear-gradient(
    to right bottom,
    #c99bfe,
    #d292f2,
    #d988e5,
    #df7fd7,
    #e476c9,
    #e872c0,
    #ec6fb7,
    #ef6cae,
    #f46ea9,
    #f870a4,
    #fc73a0,
    #ff769b
  );
}

.bg-coach-alt {
  background: linear-gradient(
    to right bottom,
    #b38ae4,
    #bb82d8,
    #c278cb,
    #c96fbe,
    #cf66b1,
    #d362a8,
    #d75e9f,
    #db5a96,
    #df5b91,
    #e35c8c,
    #e75e87,
    #eb6082
  );
}

.bg-task {
  background: linear-gradient(to right bottom, #3f5c5e, #3f4c5e);
}

#dialog {
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.58px;
}

// We apply some styles globally for messages because the tags are generated by the AI and we cannot apply
// tailwind classes inline.
// TODO: we should be able to port to tailwind once we generate the HTML in the frontend based on a structued format like
// markdown, but currently we can't as the HTML comes from the LLM in the backend.
.dialog-message {
  p {
    margin: 1em 0;
  }
  ul,
  ol {
    margin: 0.3em 0;
    padding-inline-start: 25px;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  ul:first-child,
  ol:first-child,
  p:first-child {
    margin-top: 0;
  }
  ul:last-child,
  ol:last-child,
  p:last-child {
    margin-bottom: 0;
  }
  a {
    text-decoration: underline;
  }
}
